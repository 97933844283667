import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sidebar: false
        }
    }

    toggleSidebar = () => {
        this.setState({ sidebar: !this.state.sidebar })
    }

    handleClickContacts = (link) => {
        window.open(link, '_blank');
    }

    render() {

        // let sidebarStyle = {
        //     content: { overflowY: 'auto', },
        //     overlay: { zIndex: 1029 },
        //     sidebar: { width: '90%', maxWidth: 200, zIndex: 1030, boxShadow: 'none' }
        // }

        return <div className='w-100 h-100'>
            {this.props.children}
        </div>

        // return <Sidebar
        //     styles={sidebarStyle}
        //     onSetOpen={this.toggleSidebar}
        //     // docked={this.state.docked}
        //     open={this.state.sidebar}
        //     // pullRight
        //     contentClassName='Main-Page'
        //     sidebar={<Menu sidebar
        //         language={language}
        //         contact={contact}
        //         handleClickContacts={(link) => { this.handleClickContacts(link) }}
        //         toggleSidebar={this.toggleSidebar} />
        //     }>
        //     <div className='w-100 h-100 d-flex flex-column justify-content-between align-items-center'>
        //         <div className='w-100'>
        //             <TitleBar
        //                 toggleSidebar={this.toggleSidebar}
        //                 handleClickContacts={(link) => { this.handleClickContacts(link) }}
        //                 contact={contact}
        //                 language={language} />
        //             <SubMain className='Sub_main-Content'>
        //                 {this.props.children}
        //             </SubMain>
        //         </div>
        //         <Footer
        //             contact={contact}
        //             handleClickContacts={(link) => { this.handleClickContacts(link) }} />
        //     </div>
        // </Sidebar>
    }
}

const mapStateToProps = (state) => {
    return {
        store_language: state.language
    }
}
const mapDipatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDipatchToProps)(index));