export const theme = {
    // primary: '#2a546f',
    primary: '#3A4040',
    second: '#747F7F',
    blue: '#91AAB4',
    red: '#A51E37',
    white: '#ffffff',
    gray: '#7d889a',
    Wgray: '#dee2e6',
    black: '#262626',
    cream: '#F2EBDF',

    font_xxl: '20px',
    font_xl: '18px',
    font_l: '16px',
    font_m: '14px',
    font_s: '12px',
    font_xs: '10px',
    font_xxs: '8px',
    font_xxxs: '6px',

    font_bold: '700',
}