import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from './styles/theme'
import Loadable from 'react-loadable'
import Loading from './components/Loading'
import MainContent from './components/MainContent'
const HomePage = Loadable({
    loader: () => import('./pages/Homepage'),
    loading: Loading
  })
class WebRouter extends Component {
    render() {
        let { store_language: { dictionary } } = this.props

        return <ThemeProvider theme={theme}>
            <Router>
                <MainContent dictionary={dictionary}>
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route exact path='/privacy-policy' component={Loading} />

                        <Redirect to="/" />
                    </Switch>
                </MainContent>
            </Router>
        </ThemeProvider>
    }
}

const mapStateToProps = (state) => {
    return {
        store_language: state.language
    }
}
const mapDipatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}

export default connect(mapStateToProps, mapDipatchToProps)(WebRouter);
