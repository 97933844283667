export default {
    locale: 'th',
    business_name: 'KIT JAROEN OIL',
    business_description: 'น้ำมันพืชเก่า น้ำมันพืชใช้แล้ว สามารถนำมาขายสร้างเงินได้ กิจเจริญออยล์ รับซื้อน้ำมันพืชใช้แล้ว รับซื้อน้ำมันพืชเก่า น้ำมันทอดของ น้ำมันทอดไก่ น้ำมันทอดหมู รับซื้อน้ำมันพืชใช้แล้วทุกชนิด หรือ ปี๊ปน้ำมัน รับซื้อราคาดีราคาสูง นำมาขายได้ในราคาแพง สามารถติดต่อสอบถามราคาเพิ่มเติมได้ยิ่งมีจำนวนมากยิ่งราคาดี ไปรับถึงที่',
    see_more: 'ดูเพิ่มเติม',
    close: 'ปิด',

    // title
    about_us: 'เกี่ยวกับเรา',
    contact: 'ข้อมูลติดต่อ',
    address: 'ตำแหน่งที่ตั้ง',
    photo_gallery: 'คลังรูปภาพ',
}