import styled from 'styled-components'

export const Screen = styled.div`
    width: 100%;
    height: ${props => props.height ? `${props.height}px` : '100vh'};
    background-color: ${props => props.theme.white};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const LogoImgBox = styled.div`
    width: 40vw;
    max-width: 200px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const LogoImg = styled.img`
    width: 100%;
`