export default {
    locale: 'en',
    business_name: 'KIT JAROEN OIL',
    business_description: 'All used oil trade\nVegetable oil, Chicken oil, Duck oil, Lard. Can ask for prices, the more numbers, the better price. Arrive at or can come in and deliver yourself',
    see_more: 'See more',
    close: 'Close',

    // title
    about_us: 'เกี่ยวกับเรา',
    contact: 'Contacts',
    address: 'Location',
    photo_gallery: 'Photo Gallery',
}