import React from 'react';
import ReactDOM from 'react-dom';
import ReduxThunk from 'redux-thunk'
import ReduxPromise from 'redux-promise'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'styled-components'
import { theme } from './styles/theme'
import WebRouter from './WebRouter'
import Reducers from './redux/reducers'
import { StyleList } from './styles/stylelist'

import 'font-awesome/css/font-awesome.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.css'

let store = createStore(Reducers, applyMiddleware(ReduxPromise, ReduxThunk))

ReactDOM.render(<Provider store={store}>
    <ThemeProvider theme={theme}>
        <StyleList />
        <WebRouter />
    </ThemeProvider>
</Provider>, document.getElementById('root'));


serviceWorker.unregister();
