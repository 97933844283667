import React, { Component } from 'react';
import logo from '../../assets/images/logo.png'
import { Screen, LogoImgBox, LogoImg } from './loading.style'

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenHeight: '100vh'
        }
    }


    componentDidMount() {
        this.setState({ screenHeight: window.innerHeight })
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => this.setState({ screenHeight: window.innerHeight })

    render() {
        return <Screen height={this.state.screenHeight}>
            <LogoImgBox>
                <LogoImg src={logo} alt='logo' />
            </LogoImgBox>
            {/* <i className="fas fa-spinner fa-pulse mt-3" /> */}
        </Screen>
    }
}

export default index;